
import Nl2br from '~/mixins/nl2br.js'

export default {
  name: 'Hero',
  mixins: [Nl2br],
  props: {
    hideMobile: {
      type: Boolean,
      required: false,
      default: false
    },
    mobileSize: {
      type: String,
      required: true
    },
    mobileImage: {
      type: Object,
      required: false,
      default: null
    },
    mobileVideo: {
      type: String,
      required: false,
      default: ''
    },
    link: {
      type: Object,
      required: false,
      default: null
    },
    hideDesktop: {
      type: Boolean,
      required: false,
      default: false
    },
    desktopSize: {
      type: String,
      required: true
    },
    desktopImage: {
      type: Object,
      required: false,
      default: null
    },
    desktopVideo: {
      type: String,
      required: false,
      default: ''
    },
    tint: {
      type: Boolean,
      required: true
    },
    backgroundColor: {
      type: String,
      required: false,
      default: ''
    },
    headline: {
      type: Array,
      required: true
    },
    ctas: {
      type: Array,
      required: true
    },
    hoverEffect: {
      type: Boolean,
      required: false,
      default: false
    },
    youtubeVideo: {
      type: String,
      required: false,
      default: ''
    },
    /**
     * Image display sizes for optimized images
     * Can be sent from a parent, ie the grid component
     * Defaults to 100 (i.e. browser media selector) if not sent
     */
    mobileDisplayWidthOfViewportWidth: {
      type: Number,
      required: false,
      default: 100
    },
    desktopDisplayWidthOfViewportWidth: {
      type: Number,
      required: false,
      default: 100
    },
    lazyload: {
      type: Boolean,
      required: false,
      default: true
    },
    loadImage: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  data () {
    return {
      imageBaseUrl: this.$config.imageProxyUrl
    }
  },
  computed: {
    backgroundColorCss () {
      return { backgroundColor: this.backgroundColor }
    },
    mobilePosterUrl () {
      return this.$imgproxy.transform(this.mobileImage?.filename, 'rs:fit:500/g:nowe/q:70')
    },
    desktopPosterUrl () {
      return this.$imgproxy.transform(this.desktopImage?.filename, 'rs:fit:1000/g:nowe/q:70')
    }
  },
  mounted () {
    if (this.mobileVideo) {
      this.playVideo(this.$refs.mobileVideo, this.mobileVideo)
    }
    if (this.desktopVideo) {
      this.playVideo(this.$refs.desktopVideo, this.desktopVideo)
    }
  },
  methods: {
    playVideo (ref, src) {
      const video = ref
      const m3u8 = /\.(m3u8)\?/i
      if (video) {
        // Check if source is HLS
        if (window.Hls && m3u8.test(src) && window.Hls.isSupported()) {
          const hls = new window.Hls()
          hls.loadSource(src)
          hls.attachMedia(video)
          hls.on(window.Hls.Events.MANIFEST_PARSED, () => {
            video.play().catch((err) => { console.error(`Could not play video: ${src}`, err) })
          })
          // When the browser has built-in HLS support (check using `canPlayType`),
          // we can provide an HLS manifest (i.e. .m3u8 URL) directly to the video element through the `src` property.
        } else if (m3u8.test(src) && video.canPlayType('application/vnd.apple.mpegurl')) {
          video.src = src
          video.addEventListener('loadedmetadata', () => {
            video.play().catch((err) => { console.error(`Could not play video: ${src}`, err) })
          })
        } else {
          // If the source is something other than .m3u8, just play the source that was supplied
          video.play().catch((err) => { console.error(`Could not play video: ${src}`, err) })
        }
      }
    }
  }
}
